import {
  getCoursesList,
  createCoursesList,
  deleteCourse,
  updateCoursesList,
  getOneCourse,
  CreateChapter,
  DeleteChapter,
  UpdateChapter,
  createClassCourse,
  getClassCourse,
  deleteClassCourse,
  updateClassCourse,
  getOneRelation,
  getLesson,
  deleteLesson,
  updateLesson,
  createLesson,
  getOneChapter,
  createLessonContent,
  getlessonContent,
  updateLessonContent,
  getOneLesson,
  getOnelessonContent,
} from '@/api/courses'

export default {
  FETCH_COURSES_LIST({ commit }, params) {
    return new Promise((resolve, reject) => {
      getCoursesList(params)
        .then(response => {
          commit('SET_COURSES_LIST', response.data)
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  FETCH_ONE_COURSE({ commit }, params) {
    return new Promise((resolve, reject) => {
      getOneCourse(params)
        .then(response => {
          commit('SET_ONE_COURSE', response.data)
          commit('SET_CHAPTERS', response.data.chapters)
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  CREATE_COURSE(_, data) {
    return new Promise((resolve, reject) => {
      createCoursesList(data)
        .then(response => {
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  UPDATE_COURSE(_, data) {
    return updateCoursesList(data)
  },
  DELETE_COURSE(_, id) {
    return deleteCourse(id)
  },
  // chapter
  CREATE_CHAPTER(_, data) {
    return CreateChapter(data)
  },
  FETCH_ONE_CHAPTER({ commit }, id) {
    return new Promise((resolve, reject) => {
      getOneChapter(id)
        .then(response => {
          commit('SET_ONE_CHAPTER', response.data)
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  DELETE_CHAPTER(_, data) {
    return DeleteChapter(data)
  },
  UPDATE_CHAPTER(_, data) {
    return UpdateChapter(data)
  },
  // cgt
  CREATE_CLASS_COURSE(_, data) {
    return createClassCourse(data)
  },
  FETCH_CLASS_COURSE({ commit }, params) {
    return new Promise((resolve, reject) => {
      getClassCourse(params)
        .then(response => {
          commit('SET_CLASS_COURSE', response.data)
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  FETCH_ONE_RELATION({ commit }, params) {
    return new Promise((resolve, reject) => {
      getOneRelation(params)
        .then(response => {
          commit('SET_ONE_RELATION', response.data)
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  DELETE_CLASS_COURSE(_, id) {
    return deleteClassCourse(id)
  },
  UPDATE_CLASS_COURSE(_, data) {
    return updateClassCourse(data)
  },
  // lessons
  FETCH_LESSONS({ commit }, params) {
    return new Promise((resolve, reject) => {
      getLesson(params)
        .then(response => {
          commit('SET_LESSONS', response.data)
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  FETCH_LESSON({ commit }, params) {
    return new Promise((resolve, reject) => {
      getOneLesson(params)
        .then(response => {
          commit('SET_LESSON', response.data)
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  UPDATE_LESSON(_, data) {
    return updateLesson(data)
  },
  DELETE_LESSON(_, id) {
    return deleteLesson(id)
  },
  CREATE_LESSON(_, data) {
    return createLesson(data)
  },
  // COntents
  FETCH_LESSON_CONTENT({ commit }, params) {
    return new Promise((resolve, reject) => {
      getlessonContent(params)
        .then(response => {
          commit('SET_CONTENTS', response.data)
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  FETCH_ONE_CONTENT({ commit }, id) {
    return new Promise((resolve, reject) => {
      getOnelessonContent(id)
        .then(response => {
          commit('SET_ONE_CONTENT', response.data)
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  CREATE_LESSON_CONTENT(_, data) {
    return createLessonContent(data)
  },
  UPDATE_LESSON_CONTENT(_, data) {
    return updateLessonContent(data)
  },
}
