import http from '@/libs/axios'

export function getCoursesList(params) {
  return http.get('/courses/course/', { params })
}

export function deleteCourse(id) {
  return http.delete(`/courses/course/${id}/`)
}

export function createCoursesList(data) {
  return http.post('/courses/course/', data)
}

export function updateCoursesList(data) {
  return http.patch(`/courses/course/${data.id}/`, data)
}

export function getStudentCourses(id) {
  return http({
    method: 'GET',
    url: `course/${id}/courses`,
  })
}

export function getOneCourse(id) {
  return http({
    method: 'GET',
    url: `/courses/course/${id}/`,
  })
}

export function CreateChapter(data) {
  return http({
    method: 'POST',
    url: '/courses/chapter/',
    data,
  })
}

export function getOneChapter(id) {
  return http({
    method: 'GET',
    url: `/courses/chapter/${id}/`,
  })
}

export function UpdateChapter(data) {
  return http({
    method: 'PATCH',
    url: `/courses/chapter/${data.id}/`,
    data,
  })
}

export function DeleteChapter(id) {
  return http({
    method: 'DELETE',
    url: `/courses/chapter/${id}/`,
  })
}

export function createClassCourse(data) {
  return http.post('/courses/relation/', data)
}

export function getClassCourse(params) {
  return http.get('/courses/relation/', { params })
}

export function updateClassCourse(data) {
  return http.patch(`/courses/relation/${data.id}/`, data)
}

export function deleteClassCourse(id) {
  return http.delete(`/courses/relation/${id}/`)
}

export function getOneRelation(id) {
  return http.get(`/courses/relation/${id}/`)
}

export function getLesson() {
  return http({
    method: 'GET',
    url: '/courses/lesson/',
  })
}

export function getOneLesson(id) {
  return http({
    method: 'GET',
    url: `/courses/lesson/${id}/`,
  })
}

export function createLesson(data) {
  return http({
    method: 'POST',
    url: '/courses/lesson/',
    data,
  })
}

export function updateLesson(data) {
  return http({
    method: 'PATCH',
    url: `/courses/lesson/${data.id}/`,
    data,
  })
}

export function deleteLesson(id) {
  return http({
    method: 'DELETE',
    url: `/courses/lesson/${id}/`,
  })
}

export function getlessonContent(params) {
  return http({
    method: 'GET',
    url: '/courses/content/',
    params,
  })
}

export function getOnelessonContent(id) {
  return http({
    method: 'GET',
    url: `/courses/content/${id}/`,
  })
}

export function createLessonContent(data) {
  return http({
    method: 'POST',
    url: '/courses/content/',
    data,
  })
}

export function updateLessonContent(data) {
  return http({
    method: 'PATCH',
    url: `/courses/content/${data.id}/`,
    data,
  })
}
