export default {
  SET_FOLDERS_LIST(state, data) {
    state.foldersList = data
  },
  SET_ONE_FOLDER(state, data) {
    state.one_folder = data
  },
  SET_MAIN_WINDOW(state, data) {
    if (!data.id) {
      state.main_window.children = data
      state.main_window.parent = null
    } else {
      state.main_window = data
    }
    state.one_folder = data
  },
}
