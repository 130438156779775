import {
  getClassList,
  deleteClass,
  getClass,
  createClass,
  updateClass,
  getClassStudentsList,
} from '@/api/classes'

export default {
  FETCH_CLASS_LIST({ commit }, params) {
    return new Promise((resolve, reject) => {
      getClassList(params)
        .then(response => {
          commit('SET_CLASS_LIST', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  FETCH_CLASS_STUDENTS({ commit }, params) {
    return new Promise((resolve, reject) => {
      getClassStudentsList(params)
        .then(response => {
          commit('SET_CLASS_STUDENTS_LIST', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  DELETE_CLASS(_, id) {
    return new Promise((resolve, reject) => {
      deleteClass(id)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  FETCH_CLASS({ commit }, params) {
    return new Promise((resolve, reject) => {
      getClass(params)
        .then(response => {
          commit('ONE_CLASS', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  CREATE_CLASS(_, data) {
    return new Promise((resolve, reject) => {
      createClass(data)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  UPDATE_CLASS(_, data) {
    return new Promise((resolve, reject) => {
      updateClass(data)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
}
