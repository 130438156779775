import {
  updateBranch,
  deleteBranch,
  createBranch,
  getModifiedBranch,
  getRejectBranch,
  getApprovedBranch,
} from '@/api/branches'

export default {
  FETCH_APPROVED_BRANCH_LIST({ commit }, params) {
    return new Promise((resolve, reject) => {
      getApprovedBranch(params)
        .then(response => {
          commit('SET_APPROVED_BRANCH', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  FETCH_REJECT_BRANCH_LIST({ commit }, params) {
    return new Promise((resolve, reject) => {
      getRejectBranch(params)
        .then(response => {
          commit('SET_REJECT_BRANCH', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  FETCH_MODIFIED_BRANCH_LIST({ commit }, params) {
    return new Promise((resolve, reject) => {
      getModifiedBranch(params)
        .then(response => {
          commit('SET_MODIFIED_BRANCH', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  CREATE_BRANCH(_, data) {
    return createBranch(data)
  },
  UPDATE_BRANCH(_, data) {
    return updateBranch(data)
  },
  DELETE_BRANCH(_, data) {
    return deleteBranch(data)
  },
}
