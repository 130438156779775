import http from '@/libs/axios'

export function getApprovedBranch(params) {
  return http.get('/knowledge_base/branch/approved/', { params })
}

export function getRejectBranch(params) {
  return http.get('/knowledge_base/branch/rejected/', { params })
}

export function getModifiedBranch(params) {
  return http.get('/knowledge_base/branch/modified/', { params })
}

export function createBranch(data) {
  return http.post('/knowledge_base/branch/', data)
}

export function deleteBranch(id) {
  return http.delete(`/knowledge_base/branch/${id}/`)
}

export function updateBranch(data) {
  return http.patch(`/knowledge_base/branch/${data.id}/`, data)
}
