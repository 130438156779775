import http from '@/libs/axios'

// лист классов
export function getClassList(params) {
  return http.get('/knowledge_base/group/', { params })
}

// лист учеников класса
export function getClassStudentsList(params) {
  return http.get(`/knowledge_base/group/${params.id}/students/`, { params })
}

// удаление класса
export function deleteClass(id) {
  return http.delete(`/knowledge_base/group/${id}/`)
}

export function getClass(id) {
  return http.get(`/knowledge_base/group/${id}/`)
}

export function createClass(data) {
  return http.post('/knowledge_base/group/', data)
}

export function updateClass(data) {
  return http.patch(`/knowledge_base/group/${data.id}/`, data)
}
