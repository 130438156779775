import http from '@/libs/axios'

export function getOneContact(id) {
  return http({
    method: 'GET',
    url: `/contacts/contact/${id}/`,
  })
}
export function updateContact(data) {
  return http({
    method: 'PATCH',
    url: `/contacts/contact/${data.id || data.get('id')}/`,
    data,
  })
}
export function getStudentCourses(params) {
  return http({
    method: 'GET',
    url: `/contacts/contact/${params.id}/courses_relation/`,
    params,
  })
}
export function getStudentCourse(id) {
  return http({
    method: 'GET',
    url: `/contacts/contact/${id}/courses/`,
  })
}
export function getStudentClasses(id) {
  return http({
    method: 'GET',
    url: `/contacts/contact/${id}/groups/`,
  })
}

export function getSubjectsOfContact(id) {
  return http({
    method: 'GET',
    url: `/contacts/contact/${id}/subjects/`,
  })
}
