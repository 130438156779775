export default {
  GET_FOLDER_LIST: state => state.foldersList,
  GET_COURSES_LIST: state => state.courses_list,
  GET_ONE_COURSE: state => state.one_course,
  GET_COURSE_CHAPTERS: state => state.chapters,
  GET_CLASS_COURSE: state => state.classCourse,
  GET_ONE_RELATION: state => state.oneRelation,
  GET_ONE_CHAPTER: state => state.one_chapter,
  GET_LESSON: state => state.lesson,
  GET_LESSON_CONTENT: state => state.one_chapter.contents,
  GET_QUESTION_BASE: state => state.question_base,
  GET_ONE_CONTENT: state => state.lesson.contents[0],
}
