export default {
  data() {
    return {
      $_gender_options: [
        {
          key: 'male',
          title: {
            uz: 'Erkak',
            ru: 'Мужской',
            en: 'Male',
          },
        },
        {
          key: 'female',
          title: {
            uz: 'Ayol',
            en: 'Female',
            ru: 'Женский',
          },
        },
      ],
      $_test_type_options: [
        {
          key: 'single-select',
          value: 's',
          component: 'b-form-radio-group',
          title: {
            en: 'Single select',
            ru: 'Single select',
            uz: 'Single select',
          },
        },
        {
          key: 'multiple-select',
          value: 'm',
          component: 'b-form-checkbox-group',
          title: {
            en: 'Multiple select',
            ru: 'Multiple select',
            uz: 'Multiple select',
          },
        },
        {
          key: 'essay',
          value: 'e',
          component: 'div',
          title: {
            en: 'Essay',
            ru: 'Essay',
            uz: 'Essay',
          },
        },
        {
          key: 'arbitrary choice',
          value: 'a',
          component: 'b-form-radio-group',
          title: {
            en: 'Arbitrary choice',
            ru: 'Arbitrary choice',
            uz: 'Arbitrary choice',
          },
        },
        {
          key: 'gap filling',
          value: 'g',
          component: 'div',
          title: {
            en: 'Gap filling',
            ru: 'Gap filling',
            uz: 'Gap filling',
          },
        },
      ],
    }
  },
}
