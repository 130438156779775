export default {
  SET_APPROVED_BRANCH(state, data) {
    state.approvedBranch = data
  },
  SET_REJECT_BRANCH(state, data) {
    state.rejectedBranch = data
  },
  SET_MODIFIED_BRANCH(state, data) {
    state.modifiedBranch = data
  },
}
