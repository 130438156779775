export default [
  {
    path: '/references/subjects',
    name: 'subjects-list',
    component: () => import('@/views/modules/KnowledgeBase/Subjects/index.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'references.subjects',
          i18n: 'references.subjects',
          active: true,
        },
      ],
    },
  },
  {
    path: '/administrations',
    name: 'web-settings',
    component: () => import('@/views/modules/KnowledgeBase/Administrations/Administrations.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'others.login_administration',
          active: true,
        },
      ],
    },
  },
  {
    path: '/groups',
    name: 'groups',
    component: () => import('@/views/modules/KnowledgeBase/Groups/index.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'others.groups',
          active: true,
        },
      ],
    },
  },
]
