export default [
  {
    path: '/class',
    name: 'class-list',
    component: () => import('@/views/modules/Classes/Class.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'others.class_list',
          active: true,
        },
      ],
    },
  },
  {
    path: '/class/class-info/:id',
    name: 'class-info',
    component: () => import('@/views/modules/Classes/components/ClassInfo.vue'),
    meta: {
      is_dynamic_route: true,
      breadcrumb: [
        {
          text: 'others.class_list',
          active: false,
          to: '/class',
        },
        {
          text: 'others.class_students',
          active: true,
        },
      ],
    },
  },
  {
    path: '/class/class-predmets/:id',
    name: 'class-predmets',
    component: () => import('@/views/modules/Classes/components/ClassPredmets.vue'),
    meta: {
      is_dynamic_route: true,
      breadcrumb: [
        {
          text: 'others.class_list',
          active: false,
          to: '/class',
        },
        {
          text: 'others.edit_class',
          active: true,
        },
      ],
    },
  },
  {
    path: '/class/class-detail/:id',
    name: 'class-detail',
    component: () => import('@/views/modules/Classes/components/classDetails/ClassDetail.vue'),
    meta: {
    //   is_dynamic_route: true,
      breadcrumb: [
        {
          text: 'others.class_list',
          active: false,
          to: '/class',
        },
        {
          text: 'others.class_information',
          active: true,
        },
      ],
    },
  },
]
