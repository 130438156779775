import http from '@/libs/axios'
// content base
export function getFolders() {
  return http.get('/file_management/content-file-folder/')
}

export function getOneFolder(id) {
  return http.get(`/file_management/content-file-folder/${id}/get_folder_object/`)
}

export function editFolder(data) {
  return http.patch(`/file_management/content-file-folder/${data.id}/get_folder_object/`, data)
}

export function deleteFolders(id) {
  return http.delete(`/file_management/content-file-folder/${id}/get_folder_object/`)
}

export function createFolders(data) {
  return http.post('/file_management/content-file-folder/', data)
}
