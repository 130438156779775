import http from '@axios'

export function getGroupsList(params) {
  return http.get('/knowledge_base/tag/', { params })
}

export function createGroupsList(data) {
  return http.post('/knowledge_base/tag/', data)
}

export function deleteGroups(id) {
  return http.delete(`/knowledge_base/tag/${id}/`)
}

export function updateGroups(data) {
  return http.patch(`/knowledge_base/tag/${data.id}/`, data)
}

export function getSubjects(params) {
  return http({
    method: 'GET',
    url: '/knowledge_base/subject/',
    params,
  })
}

export function createSubject(data) {
  return http({
    method: 'POST',
    url: '/knowledge_base/subject/',
    data,
  })
}

export function updateSubject(data) {
  return http({
    method: 'PATCH',
    url: `/knowledge_base/subject/${data.id}/`,
    data,
  })
}

export function getTeacherAttachedSubjects(params) {
  return http({
    method: 'GET',
    url: '/knowledge_base/subject_teacher_relation/',
    params,
  })
}

export function attachSubjectsToTeacher(data) {
  return http({
    method: 'POST',
    url: '/knowledge_base/subject_teacher_relation/',
    data,
  })
}
