export default {
  SET_FOLDERS_LIST(state, data) {
    state.foldersList = data
  },
  SET_COURSES_LIST(state, data) {
    state.courses_list = data
  },
  SET_ONE_FOLDER(state, data) {
    state.one_folder = data
  },
  SET_ONE_COURSE(state, data) {
    state.one_course = data
  },
  SET_CHAPTERS(state, data) {
    state.chapters = data
  },
  SET_CLASS_COURSE(state, data) {
    state.classCourse = data
  },
  SET_ONE_RELATION(state, data) {
    state.oneRelation = data
  },
  SET_ONE_CHAPTER(state, data) {
    state.one_chapter = data
  },
  SET_LESSONS(state, data) {
    state.lessons = data
  },
  SET_LESSON(state, data) {
    state.lesson = data
  },
  SET_ONE_CONTENT(state, data) {
    state.oneContent = data
  },
  SET_QUESTION_BASE(state, data) {
    state.question_base = data
  },
}
