export default [
  {
    path: '/course/:id/:relationId',
    name: 'user-courses',
    component: () => import('@/views/modules/Home/StudentCourseDetail/StudentCourseDetail.vue'),
    meta: {
      is_dynamic_route: true,
      breadcrumb: [
        {
          text: 'users.courses',
          active: false,
          to: '/',
        },
        {
          text: 'others.list_theme',
          active: true,
        },
      ],
      action: 'read',
      resource: 'student',
    },
  },
  {
    path: '/course/:id/view/:themeId',
    name: 'user-course-themes',
    component: () => import('@/views/modules/Home/StudentCourseDetail/ViewContentOrTest/ViewContentOrTest.vue'),
    meta: {
      is_dynamic_route: true,
      breadcrumb: [
        {
          text: 'users.courses',
          active: false,
          to: '/',
        },
        {
          text: 'others.list_theme',
          active: true,
        },
        {
          text: 'others.contents',
          active: true,
        },
      ],
      action: 'read',
      resource: 'student',
    },
  },
]
