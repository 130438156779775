export default [
  {
    path: '/finance',
    name: 'finance',
    component: () => import('@/views/modules/Finance/FinanceTable.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Финансы',
          i18n: 'Финансы',
          active: true,
        },
      ],
    },
  },
  {
    path: '/indecators',
    name: 'indecators',
    component: () => import('@/views/modules/Finance/Indecators.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Показатели',
          i18n: 'Показатели',
          active: true,
        },
      ],
    },
  },
]
