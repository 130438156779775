export default {
  GET_USERS_LIST(state) {
    return state.usersList
  },
  GET_USER_ROLES_LIST: state => state.usersRolesList,
  GET_EMPLOYEES_LIST: state => state.employeeList,
  GET_TEACHERS_LIST: state => state.teachersList,
  GET_PUPILS_LIST: state => state.pupilsList,
  GET_PUPIL: state => state.pupilList,
  GET_LEVELS_LIST: state => state.levels_list,
  GET_CLASS_LIST: state => state.class_list,
  GET_USER(state) {
    // console.log('GET_USER ', courses.courses_list.results)
    return state.user
  },
  GET_USER_AUTH_LOGS(state) {
    return state.user_auth_logs
  },
  GET_FILTERED_COURSES(state, getters, { courses }) {
    return courses.courses_list.results.filter(el => state.enrolled_courses.every(course => course.id !== el.id))
  },
  GET_USER_ACTIVITY(state) {
    return state.user_activity
  },
}
