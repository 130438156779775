export default {
  SET_USERS_LIST(state, data) {
    state.usersList = data
  },
  SET_ROLES_LIST(state, data) {
    state.usersRolesList = data
  },
  SET_EMPLOYEES_LIST(state, data) {
    state.employeeList = data
  },
  SET_TEACHERS_LIST(state, data) {
    state.teachersList = data
  },
  SET_PUPILS_LIST(state, data) {
    state.pupilsList = data
  },
  SET_LEVELS_LIST(state, data) {
    state.levels_list = data
  },
  SET_CLASS_LIST(state, data) {
    state.class_list = data
  },
  SET_USER(state, data) {
    state.user = data
  },
  SET_USER_AUTH_LOGS(state, data) {
    state.user_auth_logs = data
  },
  SET_USER_ACTIVITY(state, data) {
    state.user_activity = data
  },
}
