export default {
  foldersList: [],
  courses_list: {
    results: [],
  },
  one_folder: [],
  one_course: {},
  chapters: [],
  one_chapter: {
    lessons: [],
  },
  classCourse: [],
  contents: [],
  oneContent: {},
  lessons: {},
  lesson: {
    contents: [
      {
        lesson: null,
      },
    ],
  },
  question_base: [],
  oneRelation: {},
}
