export default [
  {
    path: '/course/content-base',
    name: 'content-base',
    component: () => import('@/views/modules/Content/ContentBase.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'others.content_base',
          active: true,
        },
      ],
    },
  },
  {
    path: '/course/course-administrations',
    name: 'course-administrations',
    component: () => import('@/views/modules/Courses/CourseAdministration.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'others.course_administration',
          active: true,
        },
      ],
    },
  },
  {
    path: '/course/course-administrations/theme-list/:id',
    name: 'theme-list',
    component: () => import('@/views/modules/Courses/ThemeList/ListTheme.vue'),
    meta: {
      is_dynamic_route: true,
      breadcrumb: [
        {
          text: 'others.course_administration',
          active: false,
          to: '/course/course-administrations',
        },
        {
          text: 'others.list_theme',
          active: true,
        },
      ],
    },
  },
  {
    path: '/course/course-administrations/theme-list/:id/:themeId',
    name: 'theme-list',
    component: () => import('@/views/modules/Courses/AddContent/AddContent.vue'),
    meta: {
      is_dynamic_route: true,
      breadcrumb: [
        {
          text: 'others.course_administration',
          active: false,
          to: '/course/course-administrations',
        },
        {
          text: 'others.list_theme',
          active: true,
        },
        {
          text: 'others.contents',
          active: true,
        },
      ],
    },
  },
]
