export default {
  SET_STUDENT_COURSE(state, data) {
    state.studentCourser = data
  },
  SET_STUDENT_CLASSES(state, data) {
    state.studentClasses = data
  },
  SET_COURSES_BY_CONTACT(state, data) {
    state.enrolled_courses = data
  },
  SET_SUBJECTS_OF_CONTACT(state, data) {
    state.subjects_of_contact = data
  },
}
