import {
  createSubject,
  getSubjects,
  updateSubject,
  getGroupsList,
  createGroupsList,
  deleteGroups,
  updateGroups,
  attachSubjectsToTeacher,
} from '@/api/knowledgeBase'

export default {
  FETCH_SUBJECTS_LIST({ commit }, params) {
    return new Promise((resolve, reject) => {
      getSubjects(params)
        .then(response => {
          commit('SET_SUBJECTS_LIST', response.data)
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  EDIT_SUBJECT(_, data) {
    return updateSubject(data)
  },
  ADD_SUBJECT(_, data) {
    return createSubject(data)
  },

  // groups
  FETCH_GROUPS_LIST({ commit }, params) {
    return new Promise((resolve, reject) => {
      getGroupsList(params)
        .then(response => {
          commit('SET_GROUPS_LIST', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  CREATE_GROUPS_LIST(_, data) {
    return createGroupsList(data)
  },

  DELETE_GROUPS_LIST(_, id) {
    return deleteGroups(id)
  },

  UPDATE_GROUPS(_, data) {
    return updateGroups(data)
  },

  ATTACH_SUBJECTS_TO_TEACHER(_, data) {
    return attachSubjectsToTeacher(data)
  },
}
