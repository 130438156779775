export default [
  {
    path: '/branches-list',
    name: 'branchesList',
    component: () => import('@/views/modules/Branches/BranchesList/index.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'others.list_branches',
          active: true,
        },
      ],
    },
  },
  {
    path: '/branches-moderation',
    name: 'branchesModeration',
    component: () => import('@/views/modules/Branches/BranchesModeration/index.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'others.moderations',
          active: true,
        },
      ],
    },
  },
  {
    path: '/branches-list/:id',
    name: 'branchUsersList',
    component: () => import('@/views/modules/Branches/BranchesList/UsersList.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'others.list_branches',
          active: false,
          to: '/branches-list',
        },
        {
          text: 'others.users_list',
          active: true,
        },
      ],
    },
  },
]
